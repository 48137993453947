<template>
  <GenericModal :title="title" size="md" :config="modalConfig" @modal-closed="modalClosed" :errorMessage="errorMessage">
    <template #openButton="{ openModal }">
      <div>
        <BaseButton variant="secondary-outline" @click.native="openModal"> Create Customer </BaseButton>
      </div>
    </template>
    <template #header>
      <div class="px-2 text-lg font-bold">{{ title }}</div>
    </template>

    <template>
      <b-row>
        <b-col md="6">
          <TextInput ref="firstName" label="First Name" name="first-name" v-model="firstName" :required="true" />
        </b-col>
        <b-col md="6">
          <TextInput ref="lastName" label="Last Name" name="last-name" v-model="lastName" :required="true" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <TextInput ref="address" label="Address" name="address" min="3" v-model="address" />
        </b-col>
        <b-col md="6">
          <TextInput ref="city" label="City" name="city" v-model="city" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <CountrySelect v-model="country" required />
        </b-col>
        <b-col md="6">
          <ProvinceDropdownInput v-model="province" required :country="country || 'CA'" :label="dropdownLabel" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <PostalInput
            v-if="this.country === 'CA'"
            ref="postalCode"
            label="ZIP/Postal Code"
            name="postal-code"
            v-model="postalCode"
            :province="province"
          />
          <ZipInput
            v-if="this.country === 'US'"
            ref="postalCode"
            label="ZIP/Postal Code"
            name="zip-code"
            v-model="postalCode"
            :province="province"
          />
          <PostalInput
            v-if="this.country === null"
            ref="zip-postal-code"
            label="ZIP/Postal Code"
            name="zip-postal-code"
            v-model="postalCode"
            :province="province"
          />
        </b-col>
        <b-col>
          <b-form-group :invalid-feedback="veeErrors.first('input-phone')">
            <template v-slot:label> Phone <span class="text-danger">*</span> </template>
            <b-form-input
              name="input-phone"
              v-model="phone"
              v-validate="{ min: 10, max: 10, required: true }"
              :state="validateState('input-phone')"
              data-vv-as="phone"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <EmailInput ref="email" label="Email" name="email" v-model="email" :required="true" />
        </b-col>
        <b-col md="6">
          <div class="flex flex-row mt-7">
            <div class="mr-3">
              <input
                id="default-checkbox"
                type="checkbox"
                v-model="goldrush"
                class="w-4 h-4 opacity-0 absolute z-10 cursor-pointer"
              />
              <div
                class="
                  mt-1
                  w-4
                  h-4
                  border
                  rounded
                  border-gray-300
                  dark:border-gray-600
                  bg-gray-100
                  dark:bg-gray-700
                  flex
                  items-center
                  justify-center
                "
                :class="{ 'bg-green-500': goldrush }"
              >
                <svg class="w-3 h-3 text-white fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
              </div>
            </div>

            <label for="default-checkbox" class="cursor-pointer">Goldrush</label>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Comment"
            label-for="input-comments"
            :invalid-feedback="veeErrors.first('input-comments')"
          >
            <b-form-textarea id="textarea" v-model="comment" placeholder="Enter comment..." rows="3"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template slot="footer" slot-scope="{ closeModal }">
      <div class="flex py-1">
        <BaseButton variant="secondary" @click.native="closeModalHandler(closeModal)" class="mr-2"> Cancel </BaseButton>
        <BaseButton variant="success" :loading="loading" @click.native="submit(closeModal)"> Create </BaseButton>
      </div>
    </template>
  </GenericModal>
</template>

<script>
import TextInput from '@/components/inputs/TextInput.vue';
import PostalInput from '@/components/inputs/PostalInput';
import ZipInput from '@/components/inputs/ZipInput';
import EmailInput from '@/components/inputs/EmailInput.vue';
import GenericModal from '@/components/modals/GenericModal.vue';
import CustomerServiceV2 from '@/lib/customer-service-v2';
import CountrySelect from '@/components/CountrySelect.vue';
import ProvinceDropdownInput from '@/components/inputs/ProvinceDropdownInput.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

export default {
  name: 'CreateCustomer',
  components: {
    GenericModal,
    TextInput,
    PostalInput,
    ZipInput,
    EmailInput,
    CountrySelect,
    ProvinceDropdownInput,
    BaseButton
  },

  data() {
    return {
      modalConfig: {
        showHeaderClose: true,
        showCloseModalButton: false
      },
      firstName: null,
      lastName: null,
      address: null,
      city: null,
      provinceStateView: {},
      provinceStateOptions: {},
      province: null,
      postalCode: null,
      email: null,
      country: this.$store.state.organization.country,
      phone: null,
      comment: null,
      errorMessage: null,
      loading: false,
      goldrush: false
    };
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  computed: {
    title() {
      return 'Create Customer';
    },
    dropdownLabel() {
      return this.country === 'CA' ? 'Province' : 'State';
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validate() {
      const lastName = this.$refs.lastName.isValid();
      const firstName = this.$refs.firstName.isValid();
      const email = this.$refs.email.isValid();

      return lastName && email && firstName;
    },
    async submit(closeModal) {
      const customValid = this.validate();

      const valid = await this.$validator.validateAll();
      if (!valid || !customValid) {
        return;
      }

      try {
        await CustomerServiceV2.createCustomer({
          organizationId: this.$store.state.organization.id,
          firstName: this.firstName,
          lastName: this.lastName,
          address: this.address,
          city: this.city,
          province: this.province,
          postal: this.postalCode ? this.postalCode.replace(/\s/g, '') : null,
          email: this.email,
          phone: this.phone,
          notes: this.comment,
          goldrush: this.goldrush,
          country: this.country
        });

        closeModal();
        this.$emit('createCustomer');
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
        this.loading = false;
      }
    },
    closeModalHandler(closeModal) {
      closeModal();
    },
    modalClosed() {
      this.errorMessage = null;
      this.loading = false;
      this.resetForm();
    },
    resetForm() {
      this.errorMessage = null;
      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.phone = null;
      this.address = null;
      this.postalCode = null;
      this.city = null;
      this.comment = null;
      this.province = null;
      this.goldrush = false;
      this.country = null;

      this.$refs.firstName.$nextTick(() => {
        this.$refs.firstName.$validator.reset();
      });

      this.$refs.lastName.$nextTick(() => {
        this.$refs.lastName.$validator.reset();
      });

      this.$refs.email.$nextTick(() => {
        this.$refs.email.$validator.reset();
      });

      this.$nextTick(() => {
        this.$validator.reset();
      });
    }
  }
};
</script>
