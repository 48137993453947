<template>
  <div class="max-w-full overflow-x-hidden">
    <div :class="{ 'mb-12 sm:mx-2': !stacked }">
      <h1 v-if="title" class="text-2xl font-medium mb-0">{{ title }}</h1>
      <!-- Consider the implementation of adding search and filtering -->
      <div class="table-overflow">
        <table v-if="columns.length" class="table-fixed min-w-full divide-y divide-gray-200">
          <caption class="sr-only">
            {{
              title
            }}
          </caption>
          <thead class="bg-gray-100">
            <tr>
              <th v-if="allowSelect" scope="col" class="px-3 py-3 text-xs font-bold tracking-wide w-8">
                <div class="relative">
                  <input
                    type="checkbox"
                    :checked="selectAll"
                    @change="toggleSelectAll"
                    class="w-4 h-4 opacity-0 absolute z-10 cursor-pointer"
                  />
                  <div
                    class="
                      w-4
                      h-4
                      border
                      rounded
                      border-gray-300
                      dark:border-gray-600
                      bg-gray-100
                      dark:bg-gray-700
                      flex
                      items-center
                      justify-center
                    "
                    :class="{ 'bg-green-500': selectAll }"
                  >
                    <svg
                      v-if="selectAll"
                      class="w-3 h-3 text-white fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                    </svg>
                  </div>
                </div>
              </th>
              <!-- We need to consider implementing some customization to the width of the column later on -->
              <th
                scope="col"
                v-for="column in columns"
                :class="[
                  'whitespace-nowrap',
                  'px-3',
                  'py-3',
                  'text-xs',
                  'tracking-wide',
                  'text-gray-900',
                  'w-24',
                  ...column.classes,
                  column.isCurrency ? 'text-right' : 'text-left'
                ]"
                :key="column.name"
              >
                <!-- If the column is sortable make it a link and show the sort button -->
                <a href="#" class="group text-gray-900 no-underline" @click="sort(column.name)" v-if="column.sortable">
                  {{ column.label }}
                  <span :class="sortClasses(column.name)">
                    <font-awesome-icon class="h-3 mb-0.5" :icon="['far', sortIcon(column.name)]" />
                  </span>
                </a>
                <!-- If the column is not sortable just show the label -->
                <p class="mb-0" v-else>{{ column.label }}</p>
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-xs tracking-wide w-24" v-if="actions"></th>
            </tr>
          </thead>
          <tbody class="min-w-full divide-y divide-gray-200 bg-white" v-if="loading || data.length === 0">
            <tr class="justify-content-center">
              <td colspan="100%" class="p-4" v-if="loading">
                <LoadingSpinner class="m-auto" />
              </td>
              <td colspan="9" class="p-4" v-if="!loading && data.length === 0">
                <div class="flex items-center justify-center m-auto">
                  <p class="py-3">{{ emptyMessage || 'No items added' }}</p>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody class="divide-y divide-gray-200 bg-white" v-else>
            <tr v-for="(row, index) in data" :key="index" :class="index % 2 !== 1 ? undefined : 'bg-gray-100'">
              <td class="px-3 py-4" v-if="allowSelect">
                <div class="relative" v-if="!row.disableSelection">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    v-model="selectedRows[row.id]"
                    class="w-4 h-4 opacity-0 absolute z-10 cursor-pointer"
                  />
                  <div
                    class="
                      w-4
                      h-4
                      border
                      rounded
                      border-gray-300
                      dark:border-gray-600
                      bg-gray-100
                      dark:bg-gray-700
                      flex
                      items-center
                      justify-center
                    "
                    :class="{ 'bg-green-500': selectedRows[row.id] }"
                  >
                    <svg
                      v-if="selectedRows[row.id]"
                      class="w-3 h-3 text-white fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                    </svg>
                  </div>
                </div>
              </td>
              <td
                v-for="column in columns"
                :key="column.name"
                :class="['px-3 py-4 text-gray-800', column.isCurrency ? 'text-right' : 'text-left']"
              >
                <!-- @slot See this for styling each column td -->
                <slot :name="column.name" :row="row" :column="column" :index="index">
                  {{ row[column.name] ? row[column.name] : '' }}
                </slot>
              </td>
              <td v-if="actions" class="px-3 py-4 text-gray-800">
                <!-- @slot Use this slot for actions -->
                <div class="flex justify-end align-center">
                  <slot name="actions" :row="row" :index="index"></slot>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="pagination || loadMore">
        <p class="ml-1 mt-4">
          Total {{ totalName ? totalName + ': ' : 'items: ' }}
          <strong>{{ pagination ? pagination.total : data.length }}</strong>
        </p>
      </div>

      <Pagination
        v-if="pagination"
        :pages="pageCount"
        :currentPage="currentPage"
        @updateCurrentPage="updateCurrentPage"
        class="mt-10"
      />
      <LoadMore v-if="loadMore" @fetchData="loadMoreData" :disabled="cannotLoadMore" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination';
import LoadMore from '@/components/ui/LoadMore';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';

/**
 * @displayName Generic Table
 *
 * Component that will display a table of data. It is meant to be used as a generic table for light data display.
 * It is not meant to be used for large data sets. If you need to display a large data set, you should use a paginated table.
 */
export default {
  name: 'GenericTable',
  components: {
    Pagination,
    LoadMore,
    LoadingSpinner
  },
  computed: {
    pageCount() {
      if (this.pagination) {
        return Math.ceil(parseInt(this.pagination.total, 10) / parseInt(this.pagination.pageSize, 10)) || 1;
      } else {
        return 0;
      }
    }
  },
  props: {
    /**
     * Columns will define the columns of the table. This is an array of objects where the keys are the same.
     *
     * @param {Object[]} columns - An array of objects that define the columns of the table.
     * @param {string} columns[].name - The name of the column. Corresponds to the property of the data model.
     * @param {string} columns[].label - The label of the column.
     * @param {boolean} columns[].sortable - If this column in sortable.
     * @param {boolean} columns[].classes - Any CSS classes to be applied to the th of this column.
     * @param {boolean} columns[].isCurrency - If this column is a currency column.
     *
     * @example [{ name: 'deviceName', label: 'Device Name' }]
     */
    columns: {
      type: Array,
      required: true
    },
    /**
     * Data will define the rows to be displayed in the table. This is an array of objects where the keys are the same.
     *
     * @example [{ deviceName: 'Device 1', sellerName: 'Seller 1', lastDrop: '2021-01-01' }]
     */
    data: {
      type: Array,
      required: true
    },
    /**
     * Title will define the title of the table.
     *
     * @example 'Cash Drop'
     */
    title: {
      type: String,
      required: false
    },
    /**
     * Actions will define whether or not the table will have an actions column.
     * If this is set to true, the table will have an empty column at the end of the table where a slot can define actions.
     */
    actions: {
      type: Boolean,
      required: false
    },
    /**
     * Pagination will define whether or not the table will include our pagination component.
     * If this is set to true, our pagination component will display below the table.
     */
    pagination: {
      type: Object,
      required: false
    },
    /**
     * Loading will determine if the loading spinner should be visible.
     * If this is set to true, our loading row of the table will appear and the rest of the rows will be hidden.
     */
    loading: {
      type: Boolean,
      required: false
    },
    /**
     * Allow Select will allow the user to select items in the table.
     * If this is set to true, a column will appear at the start of the table with a checkbox in each row.
     */
    allowSelect: {
      type: Boolean,
      required: false
    },
    /**
     * Name of the total amount
     * If this is set, the total name will be displayed, if not it will default to "items"
     */
    totalName: {
      type: String,
      required: false
    },
    /**
     * Load more will determine if the load more button should be visible.
     * If this is set to true, our load more button will appear below the table allowing the user to load more data.
     */
    loadMore: {
      type: Boolean,
      required: false
    },
    /**
     * Cannot load more will determine if the load more button should be disabled.
     * If this is set to true, our load more button will be disabled.
     */
    cannotLoadMore: {
      type: Boolean,
      required: false
    },
    /**
     * Stacked will determine if the mb-12 class will be applied to this table.
     * If this is set to true the bottom margin will be removed (to allow proper stacking).
     */
    stacked: {
      type: Boolean,
      required: false
    },
    /**
     * Empty message will determine the message to display when there is no data.
     */
    emptyMessage: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      selectedRows: {},
      selectAll: false,
      sortBy: 'name',
      sortDir: 'asc',
      currentPage: 0
    };
  },
  mounted() {
    if (!this.pagination) return;

    if (this.pagination.sortBy !== this.sortBy) {
      this.sortBy = this.pagination.sortBy;
    }

    if (this.pagination.sortDir !== this.sortDir) {
      this.sortDir = this.pagination.sortDir;
    }
  },
  watch: {
    selectedRows: {
      handler() {
        const allSelected = this.data.every((row) => this.selectedRows[row.id]);

        if (this.selectAll !== allSelected) {
          this.selectAll = allSelected;
        }
        this.$emit('selectedRows', this.getSelectedRows());
      },
      deep: true
    },
    loading() {
      if (!this.loading) {
        this.selectedRows = this.initializeSelectedRows();
      }
    },
    pagination() {
      if (this.currentPage !== this.pagination.page) {
        this.updateCurrentPage(this.pagination.page);
      }
    }
  },
  methods: {
    initializeSelectedRows() {
      const initialSelected = {};
      this.data.forEach((row) => {
        initialSelected[row.id] = false;
      });

      return initialSelected;
    },
    toggleSelectAll() {
      const inverse = !this.selectAll;

      for (const row of this.data) {
        if (row.disableSelection) continue;

        this.selectedRows[row.id] = inverse;
      }

      this.selectAll = inverse;
    },
    getSelectedRows() {
      const rowIds = Object.keys(this.selectedRows).filter((rowId) => this.selectedRows[rowId]);
      return rowIds;
    },
    sort(column) {
      this.sortBy = column;
      this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      this.$emit('sort', this.sortBy, this.sortDir);
    },
    sortIcon(column) {
      if (this.sortBy !== column) return 'chevron-down';
      return this.sortDir === 'desc' ? 'chevron-down' : 'chevron-up';
    },
    sortClasses(column) {
      if (this.sortBy === column) {
        return 'ml-2 px-1 rounded bg-gray-200 group-hover:bg-gray-300';
      } else {
        return 'invisible ml-2 rounded text-gray-400 group-hover:visible group-focus:visible';
      }
    },
    updateCurrentPage(page) {
      this.currentPage = page;
      this.$emit('updateCurrentPage', page);
    },
    async loadMoreData(callback) {
      await this.$emit('loadMoreData', callback);
    }
  }
};
</script>
