import Axios from '@/axios';

export default {
  listCustomers: async (params = {}) => {
    const response = await Axios.get(`/customer-service/v2/customers`, {
      params
    });
    return response.data.data;
  },
  retrieveCustomer: async (customerId) => {
    const response = await Axios.get(`/customer-service/v2/customers/${customerId}`);
    return response.data.data;
  },
  updateCustomer: async (customerId, params) => {
    const response = await Axios.patch(`/customer-service/v2/customers/${customerId}`, params);
    return response.data.data;
  },
  createCustomer: async (params) => {
    const response = await Axios.post(`/customer-service/v2/customers`, params);
    return response.data.data;
  },
  importCustomers: async (body) => {
    const response = await Axios.post(`/customer-service/v2/customers/import`, body);

    return response.data.data;
  },
  exportCustomers: async (organizationId) => {
    const response = await Axios({
      url: '/customer-service/v2/customers/export',
      method: 'GET',
      params: { organizationId },
      responseType: 'blob'
    });

    return response.data;
  }
};
