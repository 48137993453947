<template>
  <div>
    <slot name="openButton" :openModal="openModal"></slot>
    <LoadingButton v-if="!$scopedSlots.openButton" @click="openModal()" variant="outline">{{
      modalConfig.modalButtonLabel
    }}</LoadingButton>

    <dialog
      ref="modal"
      role="alertdialog"
      aria-labeledby="modal-title"
      aria-describedby="modal-description"
      :class="['rb-modal', size]"
    >
      <div class="rb-modal__container">
        <div
          v-if="$slots.header || modalConfig.showHeaderClose"
          id="modal-title"
          class="flex justify-between items-center p-4 border-b border-gray-300"
        >
          <slot name="header">
            <div class="text-lg rb-modal__title">
              {{ title }}
            </div>
          </slot>
          <div class="leading-none text-right">
            <button
              v-if="modalConfig.showCloseModalButton"
              type="button"
              @click="closeModal()"
              aria-label="Close modal"
              class="rb-modal--close"
            >
              <font-awesome-icon :icon="['far', 'circle-xmark']" class="text-lg" />
            </button>
          </div>
        </div>

        <div v-if="$slots.default" id="modal-description" class="rb-modal__content">
          <ErrorDisplay v-if="errorMessage" :error="errorMessage" />
          <slot name="successMessage" v-if="successMessage">
            <Alert variant="green">{{ successMessage }}</Alert>
          </slot>
          <slot />
        </div>

        <div class="flex justify-end items-center py-2 px-4 border-t border-gray-300">
          <slot name="footer" :closeModal="closeModal">
            <LoadingButton @onClick="closeModal()" class="mr-2" aria-label="Close modal">
              {{ modalConfig.cancelButtonLabel }}
            </LoadingButton>
          </slot>
        </div>
      </div>
    </dialog>
  </div>
</template>

<script>
import LoadingButton from '@/components/ui/LoadingButton.vue';
import Alert from '@/components/ui/Alert.vue';
import ErrorDisplay from '@/components/ErrorDisplay.vue';

export default {
  name: 'GenericModal',
  components: {
    LoadingButton,
    Alert,
    ErrorDisplay
  },
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md'
    },
    errorMessage: {
      type: String,
      default: null
    },
    successMessage: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    /* Not sure about this pattern. Defaults on the prop itself get overwritten */
    modalConfig() {
      const modalDefaults = {
        showHeaderClose: true,
        modalButtonLabel: 'Open Modal',
        submitButtonLabel: 'Submit',
        cancelButtonLabel: 'Cancel',
        showCloseModalButton: true
      };

      return {
        ...modalDefaults,
        ...this.config,
        title: this.title
      };
    }
  },
  methods: {
    openModal() {
      if (this.$refs.modal) {
        this.$refs.modal.showModal();
        this.$emit('modal-opened');
      }
    },
    closeModal() {
      if (this.$refs.modal) {
        this.$refs.modal.close();
        this.$emit('modal-closed');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-open {
  overflow: hidden;
}
.rb-modal {
  width: calc(100% - 2rem);
  max-height: calc(100% - 5rem);
  margin: 4rem auto 0rem;
  padding: 0;
  border-radius: 8px;
  box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  color: #2c3e50;
  z-index: 10001;
  overflow: auto;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
  }

  &__content {
    padding: 1rem;
  }

  &.sm {
    @media screen and (min-width: 30rem) {
      max-width: 30rem;
    }
  }

  &.md {
    @media screen and (min-width: 30rem) {
      max-width: 37.5rem;
    }
  }

  &.lg {
    @media screen and (min-width: 30rem) {
      max-width: 50rem;
    }
  }
}
</style>
