<template>
  <div class="col-span-12">
    <div class="flex justify-center mt-3">
      <LoadingButton variant="outline" @onClick="fetchData" :loading="loading" :disabled="disabled">
        Load More
      </LoadingButton>
    </div>
  </div>
</template>

<script>
import LoadingButton from '@/components/ui/LoadingButton';

export default {
  components: {
    LoadingButton
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.$emit('fetchData', () => (this.loading = false));
    }
  }
};
</script>
