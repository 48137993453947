<template>
  <Alert icon="exclamation" variant="red">
    {{ message }}
    <span v-if="contactSupport">Please contact support <a href="mailto:help@rafflebox.ca">help@rafflebox.ca</a>.</span>
  </Alert>
</template>
<script>
import Alert from '@/components/ui/Alert';

const codeMap = {
  'event-oversold': {
    message: 'The raffle has oversold and a winner cannot be picked.',
    contactSupport: true
  },

  'event-active': {
    message: 'The raffle is still active and prizes cannot be deleted.',
    contactSupport: false
  },

  'serial-number-already-in-use': {
    message: 'This serial number is already in use.',
    contactSupport: false
  },

  'event-has-no-tickets': {
    message: 'The event has no tickets.',
    contactSupport: false
  },

  unknown: {
    message: 'An unknown error occurred. Please try again later.',
    contactSupport: false
  }
};

export default {
  props: ['error'],
  components: {
    Alert
  },
  computed: {
    mappedError() {
      if (typeof this.error === 'string') {
        return { message: this.error, contactSupport: false };
      }

      let key = 'unknown';

      if (this.error?.response?.data?.errors[0].message) {
        key = this.error.response.data.errors[0].message;
      } else if (this.error.message) {
        key = this.error.message;
      } else if (this.errorCode) {
        key = this.errorCode;
      }

      return codeMap[key];
    },
    contactSupport() {
      return this.mappedError.contactSupport;
    },
    message() {
      return this.mappedError.message;
    }
  },
  data() {
    return {};
  }
};
</script>
